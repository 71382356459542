<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Psicologi</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            @clear='clearFilter()'
                            :hideNew='true'
                            v-model="filters['global'].value"
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem' >
                        <template #body='slotProps'>
                            <span :title='slotProps.data["calendly_id"]'>{{ slotProps.data.slug }}</span>
                        </template>
                    </Column>
                    <Column field='name' header='Nome' :sortable='true' style='min-width:12rem' />
                    <Column field='email' header='Email' :sortable='true' style='min-width:12rem' />
                    <Column field='gender' header='Gen' :sortable='true' style='min-width:2rem' />
                    <Column field='available' header='Disp.' :sortable='true' style='min-width:2rem' >
                        <template #body='slotProps'>
                            <ToggleButton
                                v-model="slotProps['data']['available']"
                                onLabel="Si"
                                offLabel="No"
                                @change="toggle(slotProps['data']['id'])"
                            />
                        </template>
                    </Column>

                    <Column field='id' header='Azioni'>
                        <template #body='slotProps'>
                            <router-link :to="this.$route['meta']['basePath'] + '/' + slotProps.data.id + '/patients'" :target='linkInNewPage ? "_blank" : "_self"'>
                                <font-awesome-icon
                                    class='text-blue-700 hover:text-blue-500 pointer ml-2'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Elenco Pazienti'
                                    :icon="['fas', 'user-injured']"
                                    size='2x'
                                />
                            </router-link>
                        </template>
                    </Column>


                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Psycho/Psychologists');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';

export default {
    components: {
        ListHeader
    },
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'toggleAvailability']),
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        log(str){
            console.log(str);
        },
        toggle(id){
            this.toggleAvailability(id).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Notifications,
        Navigations,
    ],
};
</script>
